<template>
  <div class="home" v-loading="loading">
    <swiper id="swiperBox" v-bind:options="swiperOption" ref="mySwiper">
<!--      <swiper-slide v-for="(item, index) in imgs" v-bind:key="index">-->
<!--        <el-image v-if="item" :src="item" alt="item"></el-image>-->
<!--      </swiper-slide>-->
      <swiper-slide >
        <el-image :src="imgs[0]" alt="item"></el-image>
      </swiper-slide>
      <swiper-slide >
        <el-image :src="imgs[1]" alt="item"></el-image>
      </swiper-slide>
      <swiper-slide >
        <el-image :src="imgs[2]" alt="item"></el-image>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>

    </swiper>

    <div class="yuanjing" style="display: flex;"> <!--background-color: #c5dbf9-->

      <div class="slide-five" v-show="!isMobile" style="flex:1.2;width: 40%">
      </div>
      <div class="page22" style="flex:1;text-align: left;height: 100%;padding-left: 5%">
        <h3 style="color: #9f9f9f;">企业简介|ABOUT US</h3>
        <p style="color: #9f9f9f;"></p>
        <div style="width: 90%;height: 100%">
          <p style="font-size: 14px;color: #9f9f9f;text-indent:2em;line-height:25px">
            江苏中慧传承工程建设有限公司始建于1956年，原为南通粮食局下属工程处，1996年经股份合作制改制为南通市天泰建筑安装工程总公司，2012年更名为南通天泰建设工程有限公司，2021年工商变更为江苏中慧传承工程建设有限公司。公司现有各类从业人员1828人，其中具有中高级职称人员167人，一般工程技术人员308人，拥有各类大中型建筑机械设备和仪器400多台套，能够承担各类高、大、难、新工程的施工总承包。
          </p>
          <p style="font-size: 14px;color: #9f9f9f;text-indent:2em;line-height:25px">
            长期以来，公司坚持以求真务实的态度、匠心精造的精神、智造发展的理念、诚信守诺的服务饮誉于市场，先后创建省、市级优质工程和星级标化工地158个，目前，我公司的施工队伍主要分布在江、沪、鲁等省的南通、上海、青岛等广大地区。
          </p>
        </div>
      </div>

    </div>

    <div class="swiper-slide slide-two">
      <div class="page">
        <h3 style="color: #9f9f9f;">精品工程</h3>
      </div>
      <ul class="case-item">
        <li v-for="(item,index) in caseList" :key="index" >
          <div class="item-img" v-lazy:background-image="imgserver + item.img">
            <router-link class="text-decoration" :to="{ name: 'casedetails', params: { id: item.id }}">
              <div class="case-item-hover">
<!--                <p class="hover-title">{{item.title}}</p>
                <div class="bottom"></div>-->
                <div class="more">
                  <span>MORE</span>
                </div>
              </div>
            </router-link>

          </div>
          <div class="title" >
            {{item.title}}
          </div>

        </li>


      </ul>
    </div>
    <div class="yuanjing" style="display: flex;"> <!--background-color: #c5dbf9-->
      <div class="page22" style="flex:1;text-align: left;height: 100%;padding-left: 5%">
        <h3 style="color: #9f9f9f;">企业愿景|Company Vision</h3>
        <p style="color: #9f9f9f;"></p>
        <div style="width: 90%;height: 100%">
          <p style="font-size: 14px;color: #9f9f9f;text-indent:2em;line-height:25px">
            中慧智造立志于做一个优秀的企业公民，在企业发展壮大的同时，时刻牢记自己的社会使命与责任秉承“诚信、团结、责任、高效”的核心价值观，坚持诚信为本、德行一致的发展理念，心系员工冷暖，胸怀企业兴衰，牢记社会责任，为担当“建设美丽空间，创造美好生活”的企业使命而不懈努力，为实现“汇聚科技资源，创新引领发展，成为创新与科技为核心竞争力的新时代建设集团”的企业愿景而不懈奋斗，聚全员之力，开拓创新，携手各方，精诚合作，致力于为城市建设宜居的生态家园，共建和谐丰盛的安乐生活！
          </p>
        </div>
      </div>
      <div class="slide-four" v-if="!isMobile" style="flex:1.2;width: 40%">
      </div>

    </div>
    <div class="swiper-slide slide-three">
      <div class="page">
        <h3>最新资讯</h3>
        <p>Latest News</p>
      </div>
      <div class="news-content">
        <div class="news-content-item" v-for="(news,i) in newsList" :key="i">
          <div :style="'order: '+ (i%2==0 ? 1: 3)">
            <router-link
                class="text-decoration"
                :to="{ name: 'newsdetails', params: { id: news.id }}"
            >
              <div class="item-img" v-lazy:background-image="imgserver + news.img"></div>
            </router-link>
          </div>
          <div style="order: 2">
            <el-divider>
              <i class="el-icon-apple"></i>
            </el-divider>
          </div>
          <div class="item-content" :style="'order: '+ (i%2==0 ? 3: 1)">
            <h3>{{news.title}}</h3>
<!--            <p v-html="news.content"></p>-->
            <span>{{news.createTime}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
 
<script>
import { baseService, getCasesAll, getNewsAll } from "@/services";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import device from "current-device";

export default {
  name: "HelloWorld",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      loading: true,flexValue:0.8,
      caseList: [],
      newsList: [],
      isMobile: device.mobile(),
      imgs:[require('@/assets/img/banner1.png'),require('@/assets/img/banner2.png'),require('@/assets/img/bgtop.jpg')],

      swiperOption: {
        loop: false,
        speed: 2000,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: true,
          disableOnInteraction: true,
        },
        //height: 100,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        //notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        //direction: "vertical", //水平方向移动
        //grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        //setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        //autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        //slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
       // mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
       // mousewheelControl: true, //同上
        //height: window.innerHeight - 60, // 高度设置，占满设备高度
        //resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        //observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
        //debugger: true,

        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        on: {
          //监听滑动切换事件，返回swiper对象
          // slideChange: () => {
          //   let swiper = this.$refs.mySwiper.swiper;
          //   //console.log(swiper.activeIndex); //滑动打印当前索引
          //   if (swiper.activeIndex === this.list.length - 1) {
          //     //到最后一个加载更多数据
          //     let newList = [];
          //     let listLength = this.list.length;
          //     for (let i = 0; i < 10; i++) {
          //       newList.push(listLength + i);
          //     }
          //     this.list = this.list.concat(newList);
          //   }
          // }
        }
      }
    };
  },
  created() {},
  // 如果你需要得到当前的swiper对象来做一些事情，你可以像下面这样定义一个方法属性来获取当前的swiper对象，同时notNextTick必须为true
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    baseService.all([getCasesAll( {pageNum:1, pageSize:8}), getNewsAll({ type: 1, pageNum:1, pageSize:5 })]).then(
      baseService.spread((responseCases, responseNews) => {
        this.caseList = responseCases.rows;
        this.newsList = responseNews.rows;
        this.loading = false;
      })
    );
  }
};
</script>
 
<style lang="scss" scoped>
/* .el-header {
  position: absolute;
} */
.swiper-slide {
  font-size: 24px;
  // text-align: center;
  // line-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .page {
    margin-top: 20px;
    text-align: center;
    //height: 50px;
    overflow: hidden;
    h3,
    p {
      font-size: 40px;
      font-weight: 400;
      color: #fff;
    }
  }

  .slogan {
    text-align: center;
    font-size: 48px;
    color: #fff;
    padding: 30px 0;
  }
}

.page22 {
  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
  //height: 50px;
  overflow: hidden;
  h3,
  p {
    font-size: 40px;
    font-weight: 400;
    color: #fff;
  }
}
//经典案例
.case-item {
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 30px;
  width: 100%;
  text-align: center;
  .title{
    overflow: hidden;
    width: 330px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-img {
    width: 330px;
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: inline-block;
    &:hover {
      .case-item-hover {
        opacity: 1;
        transition: all 0.4s ease-in-out;
      }
    }
  }
  li  {
    width: 330px;
    height: 290px;
    list-style: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    margin: 5px;
    position: relative;
    overflow: hidden;
    display: inline-block;

    &:hover {
      .case-item-hover {
        opacity: 1;
        transition: all 0.4s ease-in-out;
      }
    }
  }
}
//经典案例hover
.case-item-hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  background-color: rgba(225, 56, 52, 0.7);

  .hover-title {
    height: 50px;
    color: #fff;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    margin-top: 20px;
  }
  .bottom {
    border-bottom: 1px solid #fff;
    width: 60px;
    margin: 0 auto;
  }
  .more {
    width: 90px;
    padding: 5px 5px;
    margin: 0 auto;
    margin-top: 100px;
    border: 2px solid #fff;
    span {
      color: #fff;
      font-size: 20px;
    }
  }
}
// .swiper-slide:nth-child(2n) {
//   background: skyblue;
// }
// .swiper-slide:nth-child(2n-1) {
//   background: seashell;
// }
.slide-one {
  background: url(../assets/img/home_top.jpg) no-repeat center;
  background-size: cover;
}
.slide-two {
 // background: url(../assets/img/home_anli.jpg) no-repeat center;
  background-size: cover;
  background-color: #e5e5e5;
}
.slide-three {
  background: url(../assets/img/home_do.jpg) no-repeat center;//home_do
  background-size: cover;
}
.slide-four {
  background: url(../assets/img/bg3.png) no-repeat center;
  background-size: cover;
}
.slide-five {
  background: url(../assets/img/bg2.png) no-repeat center;
  background-size: cover;
}
yuanjing{
  background-color: #e5e5e5;
  padding-top: 5px;
}
//最新资讯
.news-content {
  width: 1240px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;

  &-item {
    width: 400px;
    display: flex;
    flex-direction: column;

    .item-img {
      width: 360px;
      height: 230px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-origin: content-box;
      margin: 0 auto;
    }
    .el-divider {
      background-color: #fff;
      height: 3px;
      .el-divider__text {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 0px;
        color: #fff;
      }
    }
    .item-content {
      width: 360px;
      height: 230px;
      margin: 0 auto;
      //border: 1px solid paleturquoise;
      h3 {
        font-size: 22px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        font-size: 15px;
        height: 80px;
        overflow: hidden;
        margin: 10px 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: normal !important;
        word-wrap: break-word;
      }
      span {
        display: block;
        font-size: 14px;
        text-align: end;
      }
      h3,
      p,
      span {
        color: #fff;
      }
    }
  }
}
.order {
  order: -1;
}
.order-img {
  order: 1;
}
.mobile {
  .case-item {
    li {
      width: 150px;
      height: 175px;
    }
    .item-img {
      width: 150px;
      height: 150px;
    }
    .title{
      width: 150px;
      font-size: 12px;
    }

  }
  .news-content {
    width: auto;
    margin-top: 10px;
    .news-content-item {
      width: 200px;
      .item-img {
        width: 160px;
        height: 200px;
        margin: 0 10px;
      }
      .item-content {
        width: 160px;
        height: 200px;
        margin: 0 10px;
      }
    }
  }
}
</style>